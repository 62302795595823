import styled, { css } from 'styled-components'
import { rem } from '../../../utils'
import { Container } from '@material-ui/core'

const slantedStyles = css`
  margin-top: ${props => rem(40, props.theme)};
  padding-top: ${props => rem(100, props.theme)};

  ${props => props.theme.breakpoints.up('sm')} {
    padding-top: ${props => rem(120, props.theme)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    padding-top: ${props => rem(150, props.theme)};
  }

  &::before {
    bottom: ${props => rem(20, props.theme)};
    clip-path: polygon(0 30px, 100% 0, 100% 100%, 0 calc(100% - 80px));

    ${props => props.theme.breakpoints.up('sm')} {
      clip-path: polygon(0 80px, 100% 0, 100% 100%, 0 calc(100% - 120px));
    }

    ${props => props.theme.breakpoints.up('md')} {
      top: ${props => rem(-50, props.theme)};
      clip-path: polygon(0 100px, 100% 0, 100% 100%, 0 calc(100% - 100px));
    }

    ${props => props.theme.breakpoints.up('lg')} {
      top: ${props => rem(-70, props.theme)};
      clip-path: polygon(0 125px, 100% 0, 100% 100%, 0 calc(100% - 125px));
    }

    ${props => props.theme.breakpoints.up('xl')} {
      top: ${props => rem(-90, props.theme)};
      clip-path: polygon(0 155px, 100% 0, 100% 100%, 0 calc(100% - 155px));
    }
  }
`
const flatStyles = css`
  margin: ${props => rem(64, props.theme)} 0 ${props => rem(85, props.theme)};
  padding: ${props => rem(71, props.theme)} 0 ${props => rem(64, props.theme)};

  ${props => props.theme.breakpoints.up('md')} {
    margin: ${props => rem(110, props.theme)} 0 ${props => rem(157, props.theme)};
    padding: ${props => rem(190, props.theme)} 0 ${props => rem(157, props.theme)};
  }
`

export const StyledAltBgSection = styled(Container)<{ $slanted?: boolean }>`
  position: relative;

  &::before {
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #f2f2f2;
    opacity: 1;
    position: absolute;
    z-index: -1;

    ${props => props.theme.breakpoints.up('sm')} {
      opacity: 0.5;
    }
  }

  ${props => props.$slanted ? slantedStyles : flatStyles}
`