import React from 'react'
import Grid from '@material-ui/core/Grid'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { CreditUnionIcon, IconCodeType } from '../icon/CreditUnionIcon'
import {
  StyledCardContainer,
  StyledCardIconContainer,
  StyledCardList,
  StyledTitleCheveron,
  StyledTitleText,
  StyledQuickLink,
  StyledCardWrapper,
} from './styledQuickLinksCard'

export type QuickLinksCardProps =  React.ComponentProps<typeof StyledCardContainer> & {
  title: string
  iconCode: IconCodeType
  href?: string | undefined
  target?: string | undefined
}

export const QuickLinksCard: React.FC<QuickLinksCardProps> = ({ title, iconCode, href, target, children, item=true, xs=12, sm=12, ...props }) => {
  return (
    <StyledCardContainer item={item} xs={xs} sm={sm} {...props}>
      <StyledCardWrapper container direction="column" justifyContent="flex-start" alignItems="flex-start">
        <StyledCardIconContainer item>
          <StyledTitleText href={href} target={target === '_blank' ? '_blank' : '_self'} >
            <CreditUnionIcon width={49} height={49} iconCode={iconCode}/>
          </StyledTitleText>
        </StyledCardIconContainer>
        <StyledQuickLink container direction="row" justifyContent="flex-start" alignItems="flex-start" wrap="nowrap">
          <StyledTitleText rel="nooppener noreferrer" href={href} target={target === '_blank' ? '_blank' : '_self'} >{ title }</StyledTitleText>
          <StyledTitleCheveron icon={faChevronRight}/>
        </StyledQuickLink>
      </StyledCardWrapper>
      <Grid container>
        <StyledCardList>
          {children}
        </StyledCardList>
      </Grid>
    </StyledCardContainer>
  )
}