import React from 'react'
import { gql } from '@apollo/client'
import { BannerFragment } from './__generated__/banner'
import { IMAGE_FRAGMENT } from '../../../fragments/image'
import { HomeBanners } from '../../../components/banner/HomeBanners'
import { HomeBanner } from '../../../components/banner/HomeBanner'
import { html } from '../../../utils/html'
import { isNotNull, nonNull } from '../../../utils/nonNull'

export type BannerProps = {
  data: BannerFragment[]
}

export const Banner: React.VFC<BannerProps> = ({ data }) => {
  return (
    <HomeBanners>
      {nonNull(data).map((banner, i) => {
        if (!banner.bannerImage?.[0]) {
          return null
        }

        const { url, width, height } = banner.bannerImage[0]

        if (!url || !width || !height) {
          return null
        }

        return (
          <HomeBanner key={i} i={i} image width={width} height={height} priority={i === 0} src={url}>
            {banner.bannerText && (
              <HomeBanner title text={banner.bannerText}/>
            )}
            {banner.bannerSubText && (
              <HomeBanner subtitle text={html(banner.bannerSubText)}/>
            )}
            {banner.bannerButtonField && banner.bannerButtonField.__typename === 'linkField_Link' && isNotNull(banner.bannerButtonField.url) && isNotNull(banner.bannerButtonField.customText) && (
              <HomeBanner key={banner.bannerButtonField.url} cta href={banner.bannerButtonField.url} text={banner.bannerButtonField.customText} target={banner.bannerButtonField.target === '_blank' ? '_blank' : '_self'}/>
            )}
          </HomeBanner>
        )
      })}
    </HomeBanners>
  )
}

export const BANNER_FRAGMENT = gql`
  ${IMAGE_FRAGMENT}

  fragment BannerFragment on bannerHome_BlockType {
    bannerText
    bannerSubText
    bannerImage {
      width
      height
      ...ImageFragment
    }
    bannerButtonField {
      url
      customText
      target
    }
  }
`
