import React from 'react'
import styled from 'styled-components'
import { Link, LinkProps } from '../link/link'

export type QuickLinksItemProps = React.ComponentProps<typeof StyledLinksItem> & Pick<LinkProps, 'href'> & {
  target?: string
}

export const StyledLinksItem = styled.li`
  list-style: none;
  line-height: 200%;

  ${props => props.theme.breakpoints.up('sm')} {
    line-height: ${props => props.theme.typography.pxToRem(32)};
    padding-bottom: ${props => props.theme.typography.pxToRem(12)};
  }
`

export const StyledLinksItemText = styled.a`
  color: ${props => props.theme.palette.text.secondary};
  font-size: ${props => props.theme.typography.pxToRem(17)};
  margin-bottom: ${props => props.theme.typography.pxToRem(20)};
  transition: color 0.2s ease;

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(19)};
  }

  &:hover {
    color: ${props => props.theme.palette.secondary.dark};
  }
`

export const StyledLink = styled(Link)`
  text-decoration: none;
`

export const QuickLinksItem: React.FC<QuickLinksItemProps> = ({ target, href, children, ...props }) => {
  return (
    <StyledLinksItem {...props}>
      <StyledLink href={href} passHref>
        <StyledLinksItemText rel="nooppener noreferrer" target={target === '_blank' ? '_blank' : '_self'}>{ children }</StyledLinksItemText>
      </StyledLink>
    </StyledLinksItem>
  )
}